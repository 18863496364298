@import '~global/styles';

.groupHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .searchBox {
    width: 100%;
  }
}
.homeBanner {
  margin-top: 1.5em;
  
  .title {
    margin-bottom: .5em;
  }

  .bannerDiv {
    box-shadow: unset;
    display: flex;
    margin-bottom: 3em;
    
    .details {
      display: flex;
      align-items: center;
      padding: 35px 25px;
      
      .introImg {
        margin-right: 20px;
        padding-top: 10px;
      }

      .contents {
        .closeIcon {
          float: right;
          margin-top: -10px;
          font-size: 22px;
          color: #bfc1c8;
          cursor: pointer;
        }

        h2.leadTitle {
          font-family: 'Roboto-Medium';
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0.4px;
          line-height: 24px;
          margin-bottom: 12px;
        }
    
        .leadContent {
          font-size: 16px;
          letter-spacing: 0.1px;
          line-height: 24px;
        }
      }
    }
  }
}

.paperListHeader {
  margin-top: 2em;
  margin-bottom: 1.5em;
  display: flex;
  justify-content: space-between;
}

.content {
  margin-top: .5em;
}

.loadMore {
  text-align: center;
}

.loadingLabel {
  text-align: center;
  font-size: 16px;
}

.sortWrapper {
  display: flex;
}

.actionsWrapper {
  margin-left: 10px;
  .button {
    color: $primary;
    text-transform: uppercase;
    padding: 0;
    padding-bottom: 10px;
    min-width: 100px;

    &:hover,
    &:focus {
      background: transparent;
    }

    span {
      font-family: 'Roboto-Medium';
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 16px;
    }
  }

  .popper {
    padding: 15px;
    background: $white;
    border-radius: 8px;
    box-shadow: 0 2px 30px 0 rgba(0,0,0,0.15);
    position: relative;

    &:after{
      content:'';
      position: absolute;
      border: 10px solid transparent;
      border-bottom: 10px solid $white;
      top: -20px;
      left: 40%;
    }
  }

  button.actionButton {
    background: transparent;
    color: $darkgray1;
    padding: 0;
    display: block;

    &:hover,
    &:focus {
      background: inherit;
      color: $primary;
    }

    span {
      font-family: "Roboto";
      font-size: 16px;
      letter-spacing: 0.1px;
      line-height: 16px;
      text-transform: capitalize;
      text-align: left;
    }
  }
}


@media screen and (max-width: 959px) {
  .groupHeader {
    display: block;
    text-align: right;

    .addBtn {
      margin-top: 15px;
    }
  }

  .homeBanner {
    .bannerDiv {
      padding: 20px;
      .details {
        display: block;
        padding: 0;
        position: relative;

        .introImg {
          margin-bottom: 20px;
        }
        .contents {
          .closeIcon {
            position: absolute;
            right: -10px;
            top: 0;
          }
        }
      }
    }
  }
}