@import '~global/styles';

.h5style {
  font-family: 'Roboto-Medium';
  font-size: 14px;
  letter-spacing: 0.44px;
  line-height: 16px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: normal;
}

h2 {
  span {
    font-size: 20px;
    letter-spacing: .4px;
    line-height: 24px;
  }
}
.style_backNav__iDCvu {
  span {
    display: inline-block;
  }
}

.modal {
  display: 'flex';
  align-items: 'center';
  justify-content: 'center';
  background-color: white;
  opacity: 1;
  width: 350px;
  height: 200px;
  border-radius: 20px;
  text-align: center;
}

.dashboardWrapper {
  background: $white;
  border: 1px solid $lightgray;
  border-radius: 8px;
  padding: 24px 42px;
  justify-content: space-between;

  .avatar {
    width: 105px;
    height: 105px;
    span {
      font-family: 'Roboto-Bold';
      font-size: 60px;
      letter-spacing: 0.38px;
      line-height: 71px;
      text-align: center;
      position: relative;
      top: 2px;
    }
  }
  .userName {
    font-family: 'Roboto-Medium';
    font-size: 22px;
    letter-spacing: 0.14px;
    line-height: 25px;
  }

  .mail {
    font-family: 'Roboto';
    font-size: 20px;
    margin-left: 5px;
    font-style: italic;
    letter-spacing: 0.54px;
    line-height: 25px;
    cursor: pointer;
  }

  .icon {
    vertical-align: 'middle';
    display: 'inline-flex';
  }

  .contributionInfo {
    border-radius: 10px;
    background: $offwhite;
    padding: 15px 25px;
    width: 100%;

    ul {
      padding: 0;
      list-style-type: none;
      li {
        font-size: 16px;
        letter-spacing: 0.1px;
        line-height: 19px;
        color: $lightgray2;
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        span {
          border-radius: 4px;
          background-color: #D7E1E8;
          padding: 1px 10px;
          color: #404040;
        }
      }
    }
  }
}

.paperListHeader {
  margin-top: 40px;
  margin-bottom: 1.5em;
  display: flex;
  justify-content: space-between;
}

.content {
  margin-top: .5em;
}

.loadMore {
  text-align: center;
}

.sortWrapper {
  display: flex;
}

@media screen and (max-width: 959px) {
  .dashboardWrapper {
    display: block;

    .userInfo {
      margin-bottom: 15px;
      .avatar {
        width: 45px;
        height: 45px;

        span {
          font-family: 'Roboto-Bold';
          font-size: 30px;
          letter-spacing: 0.38px;
          line-height: 20px;
          text-align: center;
          position: relative;
          top: 1px;
        }
      }
    }

    .contributionInfo {
      width: 100%;
    }
  }
}

.loadingLabel {
  text-align: center;
  font-size: 16px;
}

.styles_edit__3U2vv .MuiIconButton-label {
  color:#854ea5;
}