@import '~global/styles';

.contentWrapper {
  background: $offwhite;
  border-top-left-radius: 12px;
  padding: 60px 40px 40px 40px;
  max-height: 98vh;
  width: 100%;
}

.backNav {
  width: 80px;
  margin-bottom: 30px;
  cursor: pointer;

  h4.back {
    font-family: 'Roboto';

    .icon {
      position: relative;
      top: -1px;
      margin-right: 15px;
    }
  }
}

@media screen and (max-width: 959px) {
  .contentWrapper {
    padding: 30px 15px 20px 20px
  }
}

.backNav {
  .styles_back__3Wvtv {
    font-size: 18px;
    letter-spacing: 0.11px;
    line-height: 21px;
    color: #3a3a3a;
    display:inline-block;
  }
}

.styles_backNav__2Gf0S .styles_back__3Wvtv .styles_icon__3wAKo {
    position: relative;
    top: -1px;
    margin-right: 15px;
}