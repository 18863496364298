@import '~global/styles';

.drawer {
  min-width: 45px;
  max-width: 300px;
  flex-shrink: 0;
  display: block;
  height: 100vh;
}

div.drawerPaper {
  background-color: $secondary;
  position: inherit;
  overflow: hidden;
}

.comment:hover {
  opacity:0.7;
  cursor: context-menu;
}

.mainNav {
  padding: 45px 40px;
  display: flex;
  justify-content: flex-start; 
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .logo {
    cursor: pointer;
    width: 163px;
  }

  .navLink {
    color: $white;
    display: block;
    text-decoration: none;
    font-family: 'Roboto';
    font-size: 18px;
    letter-spacing: 0.11px;
    line-height: 21px;
    margin-top: 25px;
  
    &.active {
      color: $yellow;
    }
  
    img {
      margin-right: 13px;
    }
  }

  .notification {
    position: absolute;
    margin-left: 250px;
    margin-top: -20px;
  }

  .subLink {
    color: $white;
    display: block;
    text-decoration: none;
    font-family: 'Roboto';
    font-size: 18px;
    letter-spacing: 0.11px;
    line-height: 21px;
    margin-top: 25px;
    margin-left: 28px;
  
    &.active {
      color: $yellow;
    }
  
    img {
      margin-right: 13px;
    }
  }

  .accountDropdown {
    margin-top: 30px;
    width: 209px;
    margin-left: -1px;
    
    .button {
      background: #11204c;
      border-radius: 8px;
      border: none;
      display: inline-flex;
      align-items: center;
      padding: 15px 25px;
      color: $white;
      font-family: Roboto;
      font-size: 18px;
      letter-spacing: 0.11px;
      line-height: 21px;
      position: relative;
      width: 100%;
      cursor: pointer;

      .avatar {
        margin-right: 17px;
        background: nth($avatar-colors, random(length($avatar-colors)));
      }

      .guest {
        margin-right: 17px;
      }

      .icon {
        position: absolute;
        right: 15px;
      }
    }

    .button[aria-describedby="account-popper"] {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 1px solid #050D27;
      .icon {
        transform: rotate(180deg)
      }
    }

    .popper {
      width: 209px;
      margin: 0;
      padding: 0;
      left: 1px !important; // @k check why

      .popperButton {
        width: 100%;
        padding: 33px 25px;
        background: $darkblue;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        color: $darkorange;
        border: none;
        display: inline-flex;
        align-items: center;
        font-family: Roboto;
        font-size: 18px;
        letter-spacing: 0.11px;
        line-height: 21px;
        cursor: pointer;

        .icon {
          margin-right: 18px;
        }
      }
    }
  }
}


