@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/Roboto-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Roboto-Medium';
  font-weight: 500;
  font-style: normal;
  src: url('../../assets/fonts/Roboto-Medium.ttf')
    format('truetype');
}

@font-face {
  font-family: 'Roboto-Bold';
  font-weight: 700;
  font-style: normal;
  src: url('../../assets/fonts/Roboto-Bold.ttf')
    format('truetype');
}

@font-face {
  font-family: 'SFNS Display Regular';
  font-weight: 400;
  font-style: normal;
  src: url('../../assets/fonts/SFNS Display Regular.ttf')
    format('truetype');
}
