@import './global/styles';

html {
  box-sizing: border-box;
}

html,
body,

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  font-family: $font-family;
}
