@import '~global/styles';

.chip {
  text-align: center;
  margin-right: 5px;
  border: 1px solid #55585B;
  border-radius: 12.5px;
  height: 20px;
  max-height: 20px;
  text-transform: uppercase;
  margin-bottom: 15px;

  span {
    font-family: 'Roboto-Medium';
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.38px;
    line-height: 14px;
    color: '#55585B';
  }
  &.support {
    color: '#268055';
  }
  &.refute {
    color: $red;
  }
  &.unclear {
    color: $primary;
  }
}
