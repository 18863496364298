@import '~global/styles';

.root {
  display: flex!important;
  flex-wrap: wrap!important;
  list-style: none!important;
  margin: 0!important;
  padding: 0!important;
  margin-bottom: 15px!important;

  .chip { 
    text-align: center!important;
    margin-right: 5px!important;
    border: 1px solid #55585B!important;
    border-radius: 12.5px!important;
    height: 20px!important;
    text-transform: uppercase!important;

    span {
      padding: 0 8px!important;
      font-family: "Roboto-Medium"!important;
      color: #55585B!important;
      font-size: 12px!important;
      letter-spacing: 0.38px!important;
      line-height: 14px!important;
    }
  }
}

@media screen and (max-width: 959px) {
  .root {
    .chip {
      margin-bottom: 5px!important;
    }
  }
}