@import '~global/styles';

.wrapper {
  margin-left: 7px;
}

button.cardButton {
  background: transparent;
  font-family: "Roboto";
  font-size: 14px;
  letter-spacing: 0.44px;
  line-height: 16px;
  color: $primary;
  text-transform: capitalize;
  padding: 0;

  &:hover,
  &:focus {
    background: inherit;
  }

  img {
    margin-right: 7px;
    width: 15px;
  }

  &.edit,
  &.delete {
    margin-left: 7px;
  }

  &.delete {
    color: #E84441;

    img {
      width: 13px;
      top: -1px;
      position: relative;
    }
  }

  &.contribute {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}