@import '~global/styles';

.h5style {
  font-family: 'Roboto-Medium';
  font-size: 14px;
  letter-spacing: 0.44px;
  line-height: 16px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: normal;
}

.h2style {
  font-family: 'Roboto';
  font-size: 20px;
  letter-spacing: 0.13px;
  line-height: 24px;
  color: #3a3a3a;
  display: inline-block;
}

.tabsWrapper {
    background-color: transparent;
    height: auto;
  
  .tabs {
    border-left: 1px solid $gray;

    .tabIndicator {
      left: 0;
      right: unset;
      background-color: $primary
    }
  }

  .tab {
    padding: 10px 30px;

    .tabWrapper {
      display: block;
      text-align: left;
      text-transform: none;
    }
  }

  .tabSelected {
    .topicHeader {
      font-family: "Roboto-Medium";
    }
  }
}

.tabPanel {
  width: 100%;
}

.accordionWrapper {
  width: 100%;
  margin-bottom: 10px;

  .accordion {
    width: 100%;
    border-radius: 8px;
    background: $white;
    border: 1px solid $lightgray;
    box-shadow: none;

    .accordionSummary {
      padding: 30px;
    }

    .accordionDetails {
      padding: 10px 30px 30px 30px;
    }
  }
}