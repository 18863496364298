@import '~global/styles';

.commentsWrapper {
  width: 100%;

  .commentField {
    display: inline-flex;
    width: 100%;
    padding: 20px 30px 30px 30px;

    &.active {
      background: $offwhite;
      border-bottom: none;

      & + .comment {
        border-top: none;
      }
    }

    & + .comment {
      margin-top: 0;
    }

    .avatar {
      margin-right: 17px;
      background: nth(
        $avatar-colors,
        random(length($avatar-colors))
      );
    }

    .guest {
      margin-right: 17px;
      width: 40px;
      margin-top: -100px;
    }

    .form,
    .input {
      width: 100%;
    }
  }

  .submitBtn,
  .clearBtn {
    height: 35px;
    margin-top: 10px;
    float: right;
    padding: 0 25px;
    margin-left: 15px;
  }

  p.editMeta {
    color: $lightgray2;
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 16px;
    margin-top: 10px;
    .metaButton {
      color: $primary;
      margin-left: 3px;
      cursor: pointer;
    }
    .metaDivider {
      margin: 0 5px;
    }
  }

  .comment {
    position: relative;
    border-top: 1px solid rgba(178, 180, 189, 0.5);
    margin-top: 30px;
    padding: 30px 30px 0 30px;

    .contentWrapper {
      display: inline-flex;

      .avatar {
        margin-right: 17px;
        background: nth(
          $avatar-colors,
          random(length($avatar-colors))
        );
      }

      .name {
        font-family: 'Roboto-Medium';
        margin-bottom: 12px;
      }

      .details {
        margin-bottom: 17px;
      }

      .date {
        font-size: 14px;
        letter-spacing: 0.09px;
        line-height: 16px;
        color: $lightgray2;
      }
    }
  }
}

.actionsWrapper {
  position: absolute;
  right: 25px;
  top: 25px;

  .button {
    padding: 5px;
  }

  .popper {
    padding: 15px;
    background: $white;
    border-radius: 8px;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    min-width: 100px;

    &:after {
      content: '';
      position: absolute;
      border: 10px solid transparent;
      border-bottom: 10px solid $white;
      top: -20px;
      left: 40%;
    }
  }

  button.actionButton {
    background: transparent;
    font-family: 'Roboto';
    font-size: 15px;
    letter-spacing: 0.44px;
    line-height: 16px;
    color: $darkgray1;
    text-transform: capitalize;
    padding: 0;

    &:hover,
    &:focus {
      background: inherit;
    }

    img {
      margin-right: 7px;
      width: 15px;
    }
  }
}

.input {
  width: 100%;
  border: 1px solid rgba(178, 180, 189, 0.5);
  box-shadow: none;
  font-size: 16px;
  letter-spacing: 0.1px;
  line-height: 19px;
  border-radius: 8px;
  background: $white;
  padding: 12px 17px;
  height: 90px !important;

  &:focus,
  &:hover {
    outline-width: 0;
    outline: none;
  }
}
