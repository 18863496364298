@import '~global/styles';

div.paper {
  border: 1px solid $lightgray;
  border-radius: 8px;
  background-color: $white;
  margin-bottom: 10px;
}

.contentWrapper {
  padding: 30px;

  .draft {
    color: #E84441;
    font-size: 18px;
  }

  .headerTitle {
    cursor: pointer;
  }

  .title {
    color: $darkgray2;
    font-family: 'Roboto';
    font-size: 20px;
    letter-spacing: 0.13px;
    line-height: 24px;
    font-weight: normal;
  }
}

// Parent Title
.parentTitleWrapper {
  margin-bottom: 10px;

  .parentTitle {
    line-height: 19px;
    color: $gray3;
  }
}

.draft {
  padding-bottom: 10px;
  color: darkgrey;
  font-weight: bold;
}

// Header
.type {
  font-family: 'Roboto-Medium';
  text-transform: capitalize;
}

.bodyWrapper {
  margin-top: 12px;
  margin-bottom: 7px;

  .anchor {
    font-family: 'Roboto';
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 16px;
    color: $primary;
    text-decoration: none;
  }
}
