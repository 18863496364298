@import '~global/styles';

.form {
  padding-right: 5vh;

  .input {
    height: 45px;
    border: 1px solid rgba(178, 180, 189, 0.5);
    border-radius: 8px;
    padding: 0 17px;
    color: $gray;
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: 19px;
    background: $white;
    width: 100%;

    &.white {
      background: $white;
    }

    &.offwhite {
      background: $offwhite;
    }
  }

  .label {
    color: $darkgray1;
    font-family: 'Roboto-Medium';
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 19px;
  }

  .draftText {
    font-size: 16px;
    font-style: italic;
    letter-spacing: 0.1px;
    line-height: 19px;
    color: $gray2;
    text-align: right;
  }

  .typography {
    text-decoration: none;
  }

  .field {
    width: 100%;

    .list {
      width: 100%;

      .typography {
        color: #e84441;

        .deleteIcon {
          position: relative;
          top: 2px;
          margin-right: 6px;
        }
      }
    }
  }

  .btnBack {
    background-color: inherit;
    border: none;
    outline: none;
    margin-top: 50px;

    .btnBack:focus,
    .btnBack:active:focus,
    .btnBack.active:focus,
    .btnBack.focus,
    .btnBack:active.focus,
    .backNav {
      margin-bottom: 30px;
      width: 100%;  

      h4.back {
        font-family: 'Roboto';

        .icon {
          position: relative;
          top: 2px;
          margin-right: 15px;
        }
      }
    }
  }

  .btnContainer {
    position: relative;
    bottom: 0;
    right: 0;
    text-align: end;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.btnContainer {
  position: relative;
  bottom: 0;
  right: 0;
  text-align: end;
  margin-top: 50px;
  margin-bottom: 50px;
}

@media screen and (max-width: 959px) {
  .form {
    padding-right: 0;

    .btnBack {
      .backNav {
        margin: 0;
      }
    }

    .btnContainer {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
}


.backNav {
  .styles_back__3Wvtv {
    font-size: 18px;
    letter-spacing: 0.11px;
    line-height: 21px;
    color: #3a3a3a;
    display:inline-block;
  }
}