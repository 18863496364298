@import '~global/styles';

span {
  &.question {
    color: white;
    background-color: #834AFB;
    padding: 3px 10px 3px 10px;
    border-radius: 6px;
  }
  &.hypothesis {
    color: white;
    background-color: #BE5A00;
    padding: 3px 10px 3px 10px;
    border-radius: 6px;
  }
  &.experiment {
    color: white;
    background-color: #258640;
    padding: 3px 10px 3px 10px;
    border-radius: 6px;
  }
  &.data {
    color: white;
    background-color: #276FC1;
    padding: 3px 10px 3px 10px;
    border-radius: 6px;
  }
  &.analysis {
    color: white;
    background-color: #AB30E2;
    padding: 3px 10px 3px 10px;
    border-radius: 6px;
  }
}

.main {
  .title {
    margin-top: 10px;
    color: white
  }

  .heirarchyWrapper {
    margin-top: 13px;
    color: white;
    overflow-x: auto;
    height: 400px;
    width: 280px;
    // overflow-y: hidden;
    // overflow-x: hidden;
  
    $border: #ddd;
    $border-hover: #aaa;
    $bg-hover: #eee;
    $text: #888;
    $text-hover: #000;
    
    .questionUl {
      margin-top: 5px;
      margin-left: -30px;
      text-align: center;
      li {
        width: 0;
        opacity: 1;
        span {
          opacity: 0.7;
        }
        &.active {
          span {
            opacity: 1 !important;
          }
          ul {
            li {
              span {
                opacity: 1 !important;
              }
              ul {
                li {
                  span {
                    opacity: 1 !important;
                  }
                  ul {
                    li {
                      span {
                        opacity: 1 !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.question {
          background-color: $yellow;
          width: 20px;
          height: 20px;
          border-radius: 3px;
        }
      }
    }
    .wtree {
      text-align: center;
      li {
        list-style-type: none;
        position: relative;
        margin: 10px 0px 0px 10px;

        &:before {
          content: "";
          position: absolute;
          top: -10px;
          left: -35px;
          border-left: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          width: 35px;
          height: 21px;
        }
        &:after {
          position: absolute;
          content: "";
          top: 10px;
          left: -35px;
          border-left: 1px solid #ddd;
          border-top: 1px solid #ddd;
          width: 20px;
          height: 100%;
        }
        &:last-child:after {
          display:none;
        }
      }
    }

    .uitree {
      color: white;
      li {
        opacity: 1;
        span {
          opacity: 0.7;
        }
        &.active {
          opacity: 1 !important;
          span {
            opacity:1 !important;
          }
          ul {
            li {
              span {
                opacity: 1 !important;
              }
              ul {
                li {
                  span {
                    opacity: 1 !important;
                  }
                  ul {
                    li {
                      span{
                        opacity: 1 !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        span {
          color: white;
          &.active {
            opacity: 1 !important;
          }
          &.hypothesis {
            background-color: $orange;
            width: 20px;
            height: 20px;
            border-radius: 3px;
          }
          &.experiment {
            background-color: $green;
            width: 20px;
            height: 20px;
            border-radius: 3px;
          }
          &.data {
            background-color: $indigo;
            width: 20px;
            height: 20px;
            border-radius: 3px;
          }
          &.analysis {
            background-color: $purple;
            width: 20px;
            height: 20px;
            border-radius: 3px;
          }  
        }
      }
    }
  }
  
}

.h5style {
  margin-top: 10px;
  color: white;
  font-family: 'Roboto-Medium';
  font-size: 14px;
  letter-spacing: 0.44px;
  line-height: 16px;
  text-transform: uppercase;
  display: block;
}