
@import '~global/styles';

.questionDetails {
  border-top: 1px solid rgba(178, 180, 189, 0.5);
  padding: 30px 0;

  .tabs {
    min-height: 0;
    justify-content: center;
    padding: 0 30px;
    margin-bottom: 10px;
    .tabIndicator {
      display: none;
    }
  }

  .tab {
    padding: 0;
    text-align: left;
    min-height: 0;

    .tabWrapper {
      font-family: 'Roboto-Medium';
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.44px;
      line-height: 16px;
      text-align: left;
      color: $darkgray1;
      align-items: flex-start;
    }
  }

  .tabSelected {
    border: none;
  }
}