@import '~global/styles';

.heirarchyWrapper {
  ul.heirarchyList {
    margin-left: -10px;
    list-style: none;
    
    li.contribution {
      list-style: none;
      // activeColor
      &.question.active > div {
        border: 1px solid $yellow;
      }
      &.hypothesis.active > div {
        border: 1px solid $orange;
      }
      &.experiment.active > div {
        border: 1px solid $green;
      }
      &.data.active > div {
        border: 1px solid $indigo;
      }
      &.analysis.active > div {
        border: 1px solid $purple;
      }
    }
  }
  
  $border: #ddd;
  $border-hover: #aaa;
  $bg-hover: #eee;
  $text: #888;
  $text-hover: #000;
  $ident: 20px;
  $left: -($ident);
  
  .wtree {
    .test {
      list-style-type: none;
      margin:10px 0 10px 10px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top:-10px;
        left:$left;
        border-left: 1px solid $border;
        border-bottom:1px solid $border;
        width:$ident;
        height:80px;
      }
      &:after {
        position:absolute;
        content:"";
        top:69px;
        left:$left;
        border-left: 1px solid $border;
        border-top:1px solid $border;
        width:$ident;
        height:100%;
      }
      &:last-child:after {
        display:none;
      }
    }
  }
}

.contributionDetails {
  padding: 40px;
  color: $white;

  .title {
    color: $white;
  }

  .metaTitle {
    font-family: 'Roboto-Medium';
    margin-top: 15px;
    margin-bottom: 8px;
    color: $white;
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: 19px;
  }

  .metaSubtitle {
    font-family: 'Roboto';
    margin-top: 15px;
    margin-bottom: 8px;
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: 19px;
    color: $white;
  }

  .avatarName {
    width: 100%;
    display: inline-flex;
    align-items: center;
    font-size: 16px;
    letter-spacing: 0.1px;
    line-height: 19px;

    &.author:not(:last-of-type) {
      margin-bottom: 15px;
    }

    &.author {
      color: $white;
      .avatar {
        height: 45px;
        width: 45px;
        font-size: 22px;
        font-family: 'Roboto-Bold';
        letter-spacing: 0.14px;
        line-height: 25px;
        margin-right: 23px;
        background: nth($avatar-colors, random(length($avatar-colors)));
      }

      .defaultAvatar {
        padding: 0;
        margin-right: 23px;
        width: 40px;
        visibility: hidden;
      }
    }

    &.poster {
      font-family: 'Roboto-Medium';
      color: rgba(255, 255, 255, 0.5);
      .avatar {
        width: 26px;
        height: 26px;
        font-size: 16px;
        font-family: 'Roboto-Bold';
        letter-spacing: 0.1px;
        margin-right: 10px;
        background: nth($avatar-colors, random(length($avatar-colors)));
      }
    }
  }

  .date {
    color: rgba(255, 255, 255, 0.5);
  }
}

.styles_title__bTMaG {
  font-family: 'Roboto-Medium';
    font-size: 14px;
    letter-spacing: 0.44px;
    line-height: 16px;
    text-transform: uppercase;
    display: block;
}
