@import '~global/styles';

button {
  background-color: white;
  color: $primary;
  border: none;
  cursor: context-menu;
  font-size: 16px;
}

.footer {
  display: flex;
  margin-top: 15px;
  align-items: center;
  position: relative;

  .rightFooter {
    position: absolute;
    right: 0;
    padding: 20px;
  }

  .metaWrapper {
    display: flex;
    align-items: center;
  }

  h5.meta,
  span.meta {
    font-family: 'Roboto';
    text-transform: none;
    color: $lightgray2;
    display: inline-flex;

    &.author {
      display: inline-flex;
      align-items: center;
    }

    .avatar {
      width: 20px;
      height: 20px;
      margin-right: 8px;
      font-size: 15px;
      padding-top: 2px;
      background: nth($avatar-colors, random(length($avatar-colors)));
    }
  }
  .metaDivider {
    margin: 0 10px;
  }
  .metaButton {
    padding: 0;
    min-width: unset;
    font-family: 'Roboto';
    font-size: 14px;
    letter-spacing: 0.09px;
    line-height: 16px;
    color: $lightgray2;

    &:hover {
      background: inherit;
    }
    img {
      margin-right: 6px;
    }
  }
}

@media screen and (max-width: 959px) {
  .footer {
    flex-wrap: wrap;
  }
}
