@import '~global/styles';


.loginWrapper {
  display: flex;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background: #eff3f7;

  .contentWrapper {
    padding: 20px;
    width: 100%;
    display: flex;
  }

  .left {
    border-radius: 12px;
    background: $secondary;
    width: 50%;
    // padding: 87px 120px;
    padding: 5% 10% 8% 10%;
    position: relative;
    overflow: hidden;

    .leftAsterisk {
      height: 220px;
      width: 217px;
      position: absolute;
      bottom: -80px;
      left: -103px;
    }

    .header {
      font-family: 'Roboto-Medium';
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.15px;
      line-height: 36px;
      color: $white;
    }

    .sliderImg {
      margin-top: 65px;
      max-width: 100%;
    }
  }

  .right {
    width: 50%;
    // padding: 300px 180px;
    padding: 17% 10%;
    position: relative;

    .midAsterisk {
      height: 100px;
      width: 98px;
      position: absolute;
      top: 50px;
      left: -48px;
    }

    .header {
      margin-bottom: 9px;
      font-family: 'Roboto-Medium';
      font-size: 24px;
      letter-spacing: 0.75px;
      line-height: 28px;
    }

    .subheader {
      margin-bottom: 34px;
      color: #8A8A8A;
      font-family: 'Roboto';
      font-size: 16px;
      letter-spacing: 0.1px;
      line-height: 19px;
      font-weight: normal !important;
    }

    .loginBtn {
      box-shadow: 'none';
      background-color: '#2A4641';
      color: 'white';
      border-radius: '12px';
      text-transform: 'none';
      height: '45px';
      width: '220px';
      :hover: {
        box-shadow: 'none';
        background: '#2A4642';
        color: 'white';
        border-radius: '12px';
        text-transform: 'none';
        height: '45px';
        width: '220px';
      }

      span {
        font-family: 'SFNS Display Regular';
        font-size: 15px;
        letter-spacing: 0.1px;
        line-height: 16px;
      }

      img {
        width: 22px;
        margin-right: 7px;
      }
    }

    .linkWrapper {
      position: absolute;
      bottom: 15%;

      .link {
        color: $primary;
        font-size: 16px;
        letter-spacing: 0.1px;
        line-height: 19px;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

@media screen and (max-height: 768px) {
  .loginWrapper {
    .left {
      .sliderImg {
        margin-top: 15px;
      }
    }
  }
}

@media screen and (max-width: 959px) {
  .loginWrapper {
    .left {
      display: none;
    }
    .right {
      width: 100%;
      padding: 180px 40px;
    }
  }
}