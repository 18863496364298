@import '~global/styles';

@mixin pseudo-background {
  content: '';
  width: 215px;
  height: 215px;
  position: absolute;
  background: url('/assets/images/login/logo-icon-only.svg') no-repeat;
  background-size: contain;
}


.container {
  a  {
    color: $primary;
    display: inline-block;
  }
}

.summary {
  color: black;
  font-size: 18px;
  line-height: 1.7;

  a {
    white-space: nowrap;
    &::before {
      content: url('/assets/images/icons/link.svg');
      margin-right: 3px;
    }
  }

  svg {
    margin-bottom: 40px;
  }
}
.underlined,
.author,
.Linkstyle {
  color:#854ea5 !important;
}

.h5style {
  font-family: 'Roboto-Medium';
  font-size: 14px;
  letter-spacing: 0.44px;
  line-height: 16px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 20px !important;
}
.h6style {
  font-family: 'Roboto-Medium';
  font-size: 14px;
  letter-spacing: 0.44px;
  line-height: 16px;
  text-transform: uppercase;
  display: block;
  margin-bottom: 10px;
}

.h2style {
  color: #3a3a3a;
  font-family: 'Roboto-Bold';
  font-size: 20px;
  letter-spacing: 0.63px;
  line-height: 24px;
}

.h4style {
  font-size: 16px;
  line-height: 24px;
  font-family: "Roboto-Bold";
  font-weight: 700;
  letter-spacing: .1px;
  margin-bottom: 15px;
  display: block;
}


.author {
  display: block;
  color: $primary;
}

.contentPaper {
  padding: 30px;
  padding-right: 70px;
  margin-bottom: 48px;

  h4 {
    font-family: 'Roboto-Bold';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.1px;
    line-height: 24px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    
    &:not(:last-child) {
      margin-bottom: 30px;
    }

    strong {
      font-weight: normal;
      font-family: 'Roboto-Bold';
    }

    a.underlined {
      text-decoration: underline;
    }
  }
}

.contactPaper {
  margin-top: 40px;
  padding: 30px;
  text-align: center;
  position: relative;
  overflow: hidden;

  ::before {
    @include pseudo-background;
    bottom: -120px;
    left: -40px;
   
  }

  ::after {
    @include pseudo-background;
    top: -78px;
    right: -135px;
  }

  h3.title {
    color: $darkgray2;
    font-family: 'Roboto-Bold';
    font-size: 20px;
    letter-spacing: 0.63px;
    line-height: 24px;
    margin-bottom: 10px;
  }
}

.mediaSection {
  background: transparent;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;

  .partnerLogo {
    background: transparent;
    
    img {
      width: 135px;
    }
  
    span {
      display: block;
      color: #666B6E;
      font-size: 12px;
      letter-spacing: -0.1px;
      line-height: 14px;
    }
  }

  .iclr {
    color: #666B6E;
    font-family: 'Roboto-Bold';
    font-size: 36px;
    letter-spacing: -0.29px;
    line-height: 104px;
    margin-left: 35px;
    padding-bottom: 10px;
  }
}


